import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "blogArticleData", "pageRoute", "siteName"];
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
/**
 * Represents BlogArticle.
 * @author kkum
 */import React from 'react';
import styled from 'styled-components';
import Heading from '../../atoms/Heading';
import Image from '../../atoms/Image';
import RichText from '../../atoms/RichText/RichText';
import styles from './BlogArticle.style';
import { imageRenditions } from './config';
import Para from '../../atoms/Para';
import ThreeTile from '../ThreeTile';
import { useRouter } from 'next/router';
import urlSlug from 'url-slug';
import SocialIcons from '../../molecules/SocialIcons';
var BlogArticleDetails = function BlogArticleDetails(_ref) {
  var _blogContent$links, _blogContent$links$en, _blogContent$links2, _blogContent$links2$e, _blogContent$links3, _blogContent$links3$a;
  var className = _ref.className,
    blogArticleData = _ref.blogArticleData,
    pageRoute = _ref.pageRoute,
    siteName = _ref.siteName,
    others = _objectWithoutProperties(_ref, _excluded);
  if (!blogArticleData) {
    return null;
  }
  var title = blogArticleData.title,
    blogCategory = blogArticleData.blogCategory,
    blogImage = blogArticleData.blogImage,
    blogContent = blogArticleData.blogContent,
    webpageBlog = blogArticleData.webpageBlog,
    blogAuthor = blogArticleData.blogAuthor,
    blogDate = blogArticleData.blogDate,
    relatedContentCollection = blogArticleData.relatedContentCollection,
    shareLinks = blogArticleData.shareLinks;
  var imageSource = blogImage && blogImage.image && blogImage.image.url;
  var content = blogContent && blogContent.json;
  var richTextEmbeddedAsset = blogContent.links && blogContent.links.assets.block || [];
  var _useRouter = useRouter(),
    query = _useRouter.query;
  var author = blogAuthor ? "By ".concat(blogAuthor) : '';
  var dateFormatted = blogDate !== null ? __jsx(React.Fragment, null, __jsx("span", {
    className: "nva-blog-article__date"
  }, new Date(blogDate).toLocaleString('en', {
    timeZone: "GMT",
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })), __jsx("span", null, " \xB7 ")) : '';
  var relatedContentSection = {
    variation: 'Variation-a',
    sectionHeadline: 'More from ��Ѩ��Ƶ',
    tile: relatedContentCollection === null || relatedContentCollection === void 0 ? void 0 : relatedContentCollection.items.map(function (relatedContent) {
      var _relatedContent$sys, _relatedContent$blogC;
      return {
        sys: {
          id: relatedContent === null || relatedContent === void 0 ? void 0 : (_relatedContent$sys = relatedContent.sys) === null || _relatedContent$sys === void 0 ? void 0 : _relatedContent$sys.id
        },
        tileHeadline: relatedContent === null || relatedContent === void 0 ? void 0 : relatedContent.blogTitle,
        subHeadline: relatedContent === null || relatedContent === void 0 ? void 0 : (_relatedContent$blogC = relatedContent.blogCategory) === null || _relatedContent$blogC === void 0 ? void 0 : _relatedContent$blogC.categoryName,
        tileText: relatedContent === null || relatedContent === void 0 ? void 0 : relatedContent.blogShortDescription,
        tileImage: relatedContent === null || relatedContent === void 0 ? void 0 : relatedContent.blogImage,
        headingText: relatedContent === null || relatedContent === void 0 ? void 0 : relatedContent.blogTitle,
        tileURL: relatedContent === null || relatedContent === void 0 ? void 0 : relatedContent.url,
        relatedBlogName: relatedContent === null || relatedContent === void 0 ? void 0 : relatedContent.blogTitle
      };
    })
  };
  return __jsx("div", {
    className: "nva-blog-article ".concat(className)
  }, __jsx("div", {
    className: "nva-blog-article__container container"
  }, __jsx("div", {
    className: "nva-blog-article__header"
  }, __jsx(Heading, {
    HeadingType: "h1",
    className: "nva-blog-article__title"
  }, title), __jsx(Heading, {
    HeadingType: "h4",
    className: "nva-blog-article__category"
  }, dateFormatted, __jsx("span", {
    className: "nva-blog-article__category-label"
  }, blogCategory.categoryName)), __jsx(Para, {
    className: "nva-blog-article__author u-body2"
  }, author)), __jsx("div", {
    className: "nva-blog-article__image image-wrapper"
  }, __jsx(Image, {
    alt: blogImage.altText,
    title: blogImage.title || blogImage.altText,
    src: imageSource,
    srcSetSettingImg: {
      imageRenditions: imageRenditions
    }
  })), __jsx(RichText, {
    jsonContent: blogContent === null || blogContent === void 0 ? void 0 : blogContent.json,
    entriesContent: blogContent === null || blogContent === void 0 ? void 0 : (_blogContent$links = blogContent.links) === null || _blogContent$links === void 0 ? void 0 : (_blogContent$links$en = _blogContent$links.entries) === null || _blogContent$links$en === void 0 ? void 0 : _blogContent$links$en.block,
    linksContent: blogContent === null || blogContent === void 0 ? void 0 : (_blogContent$links2 = blogContent.links) === null || _blogContent$links2 === void 0 ? void 0 : (_blogContent$links2$e = _blogContent$links2.entries) === null || _blogContent$links2$e === void 0 ? void 0 : _blogContent$links2$e.inline,
    richTextEmbeddedAsset: blogContent === null || blogContent === void 0 ? void 0 : (_blogContent$links3 = blogContent.links) === null || _blogContent$links3 === void 0 ? void 0 : (_blogContent$links3$a = _blogContent$links3.assets) === null || _blogContent$links3$a === void 0 ? void 0 : _blogContent$links3$a.block,
    className: "nva-blog-article__content blog-content"
  }), shareLinks && __jsx("div", {
    className: "nva-blog-article__share"
  }, __jsx("span", {
    className: "nva-blog-article__share-label"
  }, "Share this post"), __jsx(SocialIcons, {
    className: "nva-blog-article__share-icons",
    socialIconsData: shareLinks,
    locationName: siteName
  })), relatedContentCollection.items.length > 0 && __jsx("div", {
    className: "nva-blog-article__related"
  }, __jsx(ThreeTile, {
    threeTileData: _objectSpread({}, relatedContentSection)
  }))));
};
BlogArticleDetails.defaultProps = {
  className: ''
};
export default styled(BlogArticleDetails).withConfig({
  componentId: "sc-1vyrxm2-0"
})(["", ";"], styles);